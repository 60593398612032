<template>
  <base-section
    id="login"
    space="56"
  >
    <v-container>
      <v-row
        justify="center"
        align="center"
      >
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-card class="evelation-12 card">
            <v-window v-model="step">
              <!--SignIn-->
              <v-window-item :value="1">
                <v-row class="">
                  <v-col
                    class="pt-6 pb-6"
                  >
                    <v-card-text>
                      <validation-observer v-slot="{ handleSubmit }">
                        <v-form
                          class="signup-form-form"
                          @submit.prevent="handleSubmit(signin)"
                        >
                          <base-section-heading :title="$t('register.sign-in')" />
                          <validation-provider
                            v-slot="{ errors }"
                            name="Username"
                            rules="required"
                          >
                            <v-text-field
                              v-model="username"
                              :error-messages="errors"
                              :color="bgColor"
                              :label="`${$t('register.user-name')}...`"
                              prepend-icon="mdi-account"
                            />
                          </validation-provider>

                          <validation-provider
                            v-slot="{ errors }"
                            name="Password"
                            rules="required"
                          >
                            <v-text-field
                              v-model="password"
                              :error-messages="errors"
                              color="secondary"
                              :label="`${$t('register.password')}...`"
                              prepend-icon="mdi-lock-outline"
                              type="password"
                            />
                          </validation-provider>

                          <div class="text-center  mt-6">
                            <pages-btn
                              large
                              color=""
                              depressed
                              class="v-btn--text success--text"
                              type="submit"
                            >
                              {{ $t('register.btn-start') }}
                            </pages-btn>
                          </div>
                        </v-form>
                      </validation-observer>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <base-material-snackbar
      v-model="snackbar"
      :type="snackbarType"
      v-bind="{
        top: true,
        center: true
      }"
    >
      {{ snackbarText }}
    </base-material-snackbar>
  </base-section>
</template>

<script>
  // import Notification from './Notification'
  import { extend } from 'vee-validate'
  // eslint-disable-next-line camelcase
  import { alpha_num, regex } from 'vee-validate/dist/rules'
  import organization from '@/mixins/organization'

  // Vee Validate.
  extend('alpha_num', alpha_num)
  extend('regex', regex)

  export default {
    name: 'SectionLogin',

    components: {
      // Notification
      PagesBtn: () => import('@/components/Btn'),
      // PagesHeading: () => import('@/components/Heading'),
    },

    mixins: [
      organization,
    ],

    props: {
      source: {
        type: String,
        default: '',
      },
      bgColor: {
        type: String,
        default: 'success',
      },
      fgColor: {
        type: String,
        default: 'white',
      },
    },

    async fetch ({ store, error }, user) {
      try {
        await store.dispatch('users/signupUser', user)
      } catch (e) {
        error({
          statusCode: 503,
          message: 'Unable to sign up user. Please try again later.',
        })
      }
    },

    data: () => ({
      step: 1,
      username: '',
      email: '',
      password: '',
      // orgName: '',
      // orgDescription: '...',
      login: '',
      snackbarType: 'success',
      snackbar: false,
      required: '',
      show: false,
      snackbarText: '',
      snackbarTimeout: 9000,
    }),

    methods: {
      signup (scope) {
        // TODO reCapcha https://habr.com/en/post/443370/
        this.$store
          .dispatch('auth/registerUser', { name: this.username, mail: this.email, pass: this.password })
          .then(response => {
            // console.log(response)
            if (response === 200) {
              this.snackbarType = 'success'
              this.snackbarText = this.$t('register.success_note')
              const that = this
              setTimeout(function () {
                that.username = ''
                that.email = ''
                that.password = ''
                that.$router.push('/')
              }, 1000)
            } else {
              this.snackbarType = 'error'
              this.snackbarText = response
            }
            this.snackbar = true
          })
      },

      reset () {
        const data = this.login
        this.$store
          .dispatch('auth/resetpassword', data)
          // .then(() => this.$router.push('/account/active'))
          .catch(error => {
            if (error) {
              this.snackbar = true
            }
          })
      },

      async signin (scope) {
        const formData = new FormData()
        formData.set('grant_type', 'password')
        formData.set('client_id', this.$store.state.auth.client_uuid)
        formData.set('username', this.username)
        formData.set('password', this.password)
        this.$store.commit('auth/userProfile', { username: this.username })
        this.$store.commit('auth/userProfile', { fd: formData })
        // const username = this.username
        // const password = this.password
        try {
          await this.$store
            .dispatch('auth/login')
            .then(response => {
              // console.log(response)
              if (response.status === 200) {
                this.$router.push({ name: 'Active Forms' })
              }
            })
        } catch (error) {
          // console.log(error)
          // console.log(error.message)
          this.snackbarType = 'error'
          if (error.message === 'Request failed with status code 401') {
            this.snackbarText = 'The user credentials were incorrect.'
          } else {
            this.snackbarText = 'There are some error, please, try later'
          }
          this.snackbar = true
        }
      },
    },
  }
</script>

<style scoped lang="scss">
  .v-input__icon--double .v-input__icon {
    margin-left: -4.25rem !important;
  }
  a.no-text-decoration {
    text-decoration: none;
  }
  #signinup-form {
    max-width: 75rem;
  }
  .signup-form-form {
    max-width: 23rem;
    margin: 0 auto;
  }
  .card {
    overflow: hidden;
  }
  .vcenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
